//import "rxjs/add/operator/finally";
import axios, {AxiosInstance, AxiosRequestConfig, AxiosResponse} from "axios";
import {FileDto, SwaggerException} from "@/shared/service-proxies/model";
import apiHttpClient from '@/shared/utils/api-http-client';
import {AppConsts} from '@/abpPro/AppConsts';
import {throwError} from "rxjs";
import {UrlHelper} from "../../../helpers";
import {message} from "ant-design-vue";
import {formatInternalValue} from "ant-design-vue/lib/vc-tree-select/src/util";
import objectHelper from "../../../helpers/objectHelper";
let userApi = {
    instance: apiHttpClient,
    namespaces:"user",
    locked: false,
    localStore: {},
    async get(code) {
        if (this.locked || this.localStorage == {}) {
            setTimeout(() => {
                return this.get(code)
            }, 100)
        } else {
            return this.localStore[code].children
        }
    },
    async getList(params){
        let url_ = AppConsts.remoteServiceBaseUrl + "/api/services/app/"+this.namespaces+"/GetList";
        let options_ = {
            method: "GET",
            url: UrlHelper.createGetRequest(url_, params),
            headers: {
                Accept: "application/json",
            },
        };
        const _response = await this.getInstance().request(options_);
        console.log(_response)
        if (_response.status != 200) {
            console.log('请求异常')
            console.log(_response)
            throwError(_response.data)
            //todo toast
        }
        return _response.data
    },
    getInstance: function () {
        return this.instance = this.instance ? this.instance : axios.create()
    },

    getById: async (itemId) => {

    },
    getByCode: async (code) => {

    },
    async save(item) {
        console.log('???????')
        let url_ = AppConsts.remoteServiceBaseUrl + "/api/services/app/"+this.namespaces+"/SaveSysInterface";
        console.log('beformClone')
        var content = objectHelper.deepClone(item)
        console.log(item.params)
        content.params = JSON.stringify(item.params)
        console.log(item.params)
        content.headers = JSON.stringify(item.headers)
        console.log(item.params)
        content.fields = JSON.stringify(item.fields)
        let options_ = {
            method: "post",
            url: url_,
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json-patch+json",
    },
            data: content
        };
        console.log(options_)
        const _response = await this.getInstance().request(options_);
        console.log(_response)
        if (_response.status != 200) {
            console.log('请求异常')
            console.log(_response)
            throwError(_response.data)
            //todo toast
        }
        return _response.data
    }
}

export default userApi