<template>
  <a-spin :spinning="spinning">
    <section>
      <div class="modal-header" v-if="!isShow">
        <div class="modal-title">
          <a-icon type="unordered-list" />项目人员授权
        </div>
      </div>

      <!-- <react-table
        :table-data="tableData"
        :actions-type="actionsType"
        :columns="columns"
        :hide-row-selection="true"
        :hide-pagination="true"
        :scroll="{x: 0}"
        :current-page="pageNumber"
      > -->
      <ele-table
        :columns="columns"
        :table-data="tableData"
        :actions-type="actionsType"
        :is-full="false"
        :hide-row-selection="true"
        :hide-pagination="true"
      >
        <a-row :gutter="24" type="flex" style="text-align: right;">
          <a-col class="gutter-row" :span="6" offset="18">
              <!-- <a-button @click="close"><a-icon type="close-circle" />{{l("关闭")}}</a-button> -->
              <a-button type="primary" @click="addAuth"><a-icon type="plus" />{{ l("添加") }}</a-button>
          </a-col>
        </a-row>
      </ele-table>
       <!-- <div class="modal-footer mf">
        <a-button :disabled="saving" @click="_close()" type="button">
          <a-icon type="close-circle" />
          {{ l("关闭") }}
        </a-button>
        <a-button :loading="saving" :type="'primary'" @click="addAuth">
          <a-icon type="plus" />
          {{ l("添加") }}
        </a-button>
      </div> -->
      
    </section>



  </a-spin>
</template>

<script>
import { AppComponentBase, ModalComponentBase } from "@/shared/component-base";
import { ProjectAgreementServiceProxy } from "../project-agreement/services/project-agreement-proxies";
import { CommonServiceProxy } from "@/shared/common-service";
import { AppConsts } from "@/abpPro/AppConsts";
import { environment } from "@/environments/environment";
import EleTable from "@/components/ele-table";
import ConfigList from "../project-agreement/config-list";
import {ModalHelper  } from "@/shared/helpers";
import modalHelper from "@/shared/helpers/modal/modal-helper";
import userSelectForm from '@/app/admin/users/selectForm/index.vue'
import entityHelper from "@/shared/helpers/EntityHelper.js";
import moment from "moment";
import {message} from "ant-design-vue";


export default {
  name: "authed-user-list",
  mixins: [AppComponentBase, ModalComponentBase],
    // props: ["id","projectType","isShow"],
  props: ['isShow'],
  components: { EleTable },
  data() {
    return {
      // isShow:false,
      spinning: false,
      // 表单
      formLayout: "horizontal",
      isDisabled: false,
      tableKey: "",
      commonService: null,
      projectService: null,
        // projectType:0,
      tableData: [],
      // 总数
      totalItems: 0,
      // 当前页码
      pageNumber: 1,
      // 共多少页
      totalPages: 1,
      // 条数显示范围
      pagerange: [1, 1],
      // 显示条数
      pageSizeOptions: ["10", "20", "30", "40"],
      actionsType: {},
      formItemLayout: {
        labelCol: {
          span: 6,
        },
        wrapperCol: {
          span: 16,
        },
      },

      // 用户表格
      columns: [
        {
          title:'用户',
          dataIndex: "userName",
          sorter: false,
          align: "center",
        },
        {
          title:'授权时间',
          dataIndex: "creationTime",
          sorter: false,
          align: "center",
          customRender: function (text, record, index) {
            if (text) {
              return text.split("T")[0];
            }
            return "";
          },
          scopedSlots: { customRender: "creationTime" },
        },
        {
          title: this.l("Action"),
          dataIndex: "actions",
          scopedSlots: { customRender: "actions" },
          fixed: "right",
        },
      ],
    };
  },
  created() {
    this.commonService = new CommonServiceProxy(this.$apiUrl, this.$api)
    this.projectService = new ProjectAgreementServiceProxy(
      this.$apiUrl,
      this.$api
    );
    this.fullData(); // 模态框必须,填充数据到data字段
  },
  mounted() {
    if (this.params) {
      this.$nextTick(() => {});
    }
    this.initActionsType();
    this._getAuthedUserList();
  },
  methods: {
    async _getAuthedUserList() {
      this.spinning = true;
      try {
        let res = await this.commonService.get({
          url: '/api/services/app/UnnaturalPersonProjectAuthorized/getAuthedUserList',
          params: {projectId: this.id}
        });
        this.tableData = res.items.map(item => {
          return {
            ...item,
            creationTime: moment(item.creationTime).format("YYYY-MM-DD"),
          }
        })
        this.spinning = false;
      } catch (error) {
        this.spinning = false;
      }
    },
    deleteItem(id){
      this.commonService.delete({
        url: '/api/services/app/UnnaturalPersonProjectAuthorized/DeleteProjectAuthorized',
        params:id
      }).then((res)=>{
        // console.log(res)
        if (res){
          message.success('取消授权成功！')
          this.$nextTick(()=>{
            this._getAuthedUserList();
          })
        }else{
          message.error('取消授权失败！')
        }
      })

    },
    addAuth() {
      modalHelper.create(userSelectForm, {}, {
        confirm: (data) => {
          console.log(data)
          let  user =  data
           let auth = {
             userId:user.id,
             userName:user.userName,
             projectId:this.id,
             
           }
              this.commonService.post({
                url: '/api/services/app/UnnaturalPersonProjectAuthorized/SaveProjectAuthorized',
                params:auth
          }).then((res)=>{
             console.log(res)
             if (res){
                message.success('授权成功！')
                this.$nextTick(()=>{
                  this._getAuthedUserList();
                })

             }else{
               message.error('授权失败！')
             }
          })
        },
        width: "400px",
        isChange: true
      })
    },
    initActionsType() {
      let _this = this
      let obj = {
        isShow: true,
        fns: {
          dropList:[],
          delete: {
            granted: true, name: this.l("Delete"), fn: (data) => {
               console.log(data)
              _this.deleteItem(data.id)
            }
          },
        },
      };
      this.actionsType = obj;
    },
    _close() {
      if (this.isShow) {
        this.$emit('emitclose')
      } else {
        this.close()
      }
    },

  },
};
</script>

<style scoped lang="less">

.modal-header {
  .anticon-share-alt {
    margin-right: 10px;
  }
}

.form-wrapper {
  .ant-form-item {
    margin-bottom: 14px;
  }
}
.mf{
  margin: 0;
  margin-top: 10px;
}

</style>
