<template>
    <a-spin :spinning="spinning">
        <div class="modal-header" style="margin-bottom: 10px">
            <div class="modal-title">
                <a-icon type="medicine-box"/>
                <span>选择用户</span>
            </div>
        </div>
    <a-form-model :rules="validModel" ref="ruleForm" :model="formModel">
        <a-form-model-item label="用户" prop="name">
            <!-- <a-select :showSearch="true" placeholder="请选择用户"  v-model="formModel.selectedUserId" :options="userOptions">
            </a-select> -->
            <a-select :showSearch="true" placeholder="请选择用户"  v-model="formModel.selectedUserId" :filter-option="filterOption">
                <a-select-option v-for="(it, i) in userList" :key="i" :value="it.id">{{it.userName}}</a-select-option>
            </a-select>
        </a-form-model-item>
    </a-form-model>
    <div class="modal-footer">
        <a-button type="danger" @click="cancel">取消</a-button>
        <a-button type="primary" @click="confirmEvent">确定</a-button>
    </div>
    </a-spin>
</template>

<script>
    import {AppComponentBase} from "@/shared/component-base";
    import {ModalComponentBase} from "@/shared/component-base";
     import userApi from "@/shared/service-proxies/User/services/user-proxies";
    export default {
        name: "user-select-from",
        mixins: [AppComponentBase, ModalComponentBase],
        data(){
             return {
                 _userServiceProxy: null,
                 spinning:false,
                 fieldMap:{
                     key:'name',
                     label:'name',
                     value:'name'
                 },
                 userList:[],
                 formModel:{
                     selectedUserId:"",
                 },
                 validModel:{},
                 formItemLayout:"",

             }
        }
        ,created() {
             this.loadData()
        },
         computed:{
             userOptions(){
                  return  this.userList.map((item)=>{
                      return {
                          label:item.userName,
                          value:item.userName,
                          title:item.userName
                      }
                  })
             }
         },
         methods:{
            filterOption(input, option) {
                return (
                    option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
                );
            },
             confirmEvent(){
                 let userInfo =  this.userList.find((item)=>{
                    return  item.id   ==  this.formModel.selectedUserId
                 })
                //  console.log(userInfo)
                 this.confirm(userInfo)
                 this.close()
             },
             cancel(){
                 this.close()
             },
             loadData(){
                 userApi.getList({
                     filterText: '',
                     sorting: '',
                 }).then(
                     (data)=>{
                         console.log(data)
                         this.userList = data.items
                     }
                 ).catch((error)=>{console.log(error)})
             }
         }
    }
</script>

<style scoped>

</style>